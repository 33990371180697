import { Container, Row, Col, Card } from 'react-bootstrap';

export default function PrivacyPolicyInfo() {
  return (

      <section>
        <div className="page-header min-vh-100">
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col xl={9} lg={12} className="d-flex flex-column ms-auto me-auto ms-lg-auto">
                <Card className="d-flex blur shadow-lg mt-4 my-lg-6 mb-5">
                  <Card.Header className="p-3 position-relative bg-gradient-dark-sp shadow-dark border-radius-lg">
                    <h3 className="text-white mb-0"> Privacy Policy</h3>
                  </Card.Header>
                  <Card.Body className="p-4">
                    <h6 className="">Last updated: April 24, 2024</h6>
                    <p className="pb-3 text-start">
                      Thank you for visiting eliasglyptis.com portfolio personal website. Your privacy is important to me. This Privacy Policy outlines how Google Analytics collects, uses, and protects the information you provide when using my website.
                    </p>
                    <h6 className="">Information Collected by Google Analytics</h6>
                    <p className="pb-3 text-start">
                     When you visit my website, Google Analytics may collect certain information automatically, including your IP address, browser type, operating system, referring URLs, and browsing actions and patterns. Google Analytics uses cookies to track this information in order to analyze trends, administer the site, and track user navigation.
                    </p>
                    <h6 className="">Use of Cookies</h6>
                    <p className="pb-3 text-start">
                     Cookies are small files stored on your device that allow websites to recognize and remember your preferences. My website uses cookies from Google Analytics to track user interactions. These cookies collect information about how visitors use my site, including the pages they visit, the time spent on each page, and any links clicked. This information helps us improve my website and provide a better user experience.
                    </p>
                    <h6 className="">Contact Form</h6>
                    <p className="pb-3 text-start">
                     If you choose to contact us through the contact form on my website, I collect the personal information you provide, such as your name and email address. I use this information solely for the purpose of responding to your inquiry and providing customer support.
                    </p>
                    <h6 className="">Data Security</h6>
                    <p className="pb-3 text-start">
                     I take reasonable precautions to protect the personal information collected through my website by Google Analytics from unauthorized access, use, or disclosure. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and I cannot guarantee the absolute security of your data.
                    </p>

                    <h6 className="">Third-Party Links</h6>
                    <p className="pb-3 text-start">
                     My website may contain links to third-party websites or services that are not owned or controlled by me. I am not responsible for the content or privacy practices of these third-party sites. I encourage you to review the privacy policies of any third-party websites you visit.
                    </p>

                    <h6 className="">Changes to This Privacy Policy</h6>
                    <p className="pb-3 text-start">
                     I reserve the right to update or change my Privacy Policy at any time. Any changes will be posted on this page with an updated "Last updated" date. Your continued use of my website after any modifications to the Privacy Policy will constitute your acknowledgment of the changes and your consent to abide and be bound by the updated Privacy Policy.
                    </p>

                    <h6 className="">Contact Me</h6>
                    <p className="pb-3 text-start">
                     If you have any questions about this Privacy Policy, please contact Google Analytics via their website.By using my website, you consent to the terms of this Privacy Policy.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
     
  );
}
