import { Container, Row, Col } from 'react-bootstrap';
//import homeCoverImage from '../assets/images/home/elias.jpg';
import './css/HomeBackground.css';

export default function HomeBackground() {
  return (
    <section id="cover" className="custom-jumbotron">
      <Container fluid>
        <Row className="align-items-center">
          <Col xs={12} className="text-center">
            <h1 className="title display-4 mb-4">Elias <span className="bold">Glyptis</span></h1>
            <h3 className="text-white text-center fw-light mb-0 ms-1">Ambitious fullstack web developer. Loves to code.</h3>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
