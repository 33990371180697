import React from 'react';
import Layout from '../components/Layout';
import HomeBackground from '../components/HomeBackground';
import Quote from '../components/Quote';
import HomeAbout from '../components/HomeAbout';
import Social from '../components/Social';


const About = () => {
  return (
    <Layout>
      <HomeBackground />
      <Quote />
      <HomeAbout />
      <Social />
    </Layout>
  );
};

export default About;
