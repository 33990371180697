import { Container, Row, Col, Button } from 'react-bootstrap';
import skillsBackgroundImage from '../assets/images/backgrounds/skills.jpeg';

const SkillsBackground = () => {
  return (
    <div className="page-header min-vh-80" style={{backgroundImage: `url(${skillsBackgroundImage})`}} loading="lazy">
      <span className="mask bg-gradient-dark opacity-8"></span>
      <Container>
        <Row>
          <Col lg={12} className="d-flex justify-content-start flex-column">
            <h1 className="text-white text-start mb-4">My Skills</h1>
            <h3 className="text-white text-start fw-light mb-0 ms-1">Front & backend technologies I currently work or worked with in the past.</h3>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SkillsBackground;
