import React from 'react';
import Header from './Header';
import Footer from './Footer';
import CookieConsentBanner from './CookieConsentBanner';



const Layout = ({ children }) => {
  return (
    <div className="App">
      <Header />
      {children}
      <CookieConsentBanner />
      <Footer />
      
    </div>
  );
};

export default Layout;
