import React from 'react';
import Layout from '../components/Layout';
// import ProjectsBackground from '../components/ProjectsBackground';
import ContactFormEmail from '../components/ContactFormEmail.js';


const About = () => {
  return (
    <Layout>
      <ContactFormEmail />
    </Layout>
  );
};

export default About;
