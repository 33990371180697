import React from 'react';
import Layout from '../components/Layout';
import ProjectsBackground from '../components/ProjectsBackground';
import ProjectsCards from '../components/ProjectsCards.js'


const About = () => {
  return (
    <Layout>
      <ProjectsBackground />
      <ProjectsCards />
      
    </Layout>
  );
};

export default About;
