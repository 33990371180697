import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; 

const NotFoundPage = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1 className="text-center">404 Error</h1>
          <p className="text-center">Page not found. Hmmm... Perhaps you have entered a wrong URL?</p>
          <div className="text-center">
            <Link to="/">
              <Button variant="primary">Return to eliasglyptis.com</Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFoundPage;
