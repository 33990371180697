import React from 'react';
import { Container, Col, Row, Card, Image, Badge } from 'react-bootstrap';
import { SKILLS } from '../data/data.js'; 
import angularImage from '../assets/images/technologies/angular.png';

const renderCategory = (category) => (
  <Row>
    <Row className="text-start mt-5 mb-n3">
      <Col lg={6}>
        <h3 className="fw-light mb-0 ms-1">{category.category}</h3>
      </Col>
    </Row>
    {Object.values(category).filter(item => typeof item === 'object').map(tech => (
      <Col sm={12} md={6} lg={4} key={tech.name} className="mt-4">
        <Card>
          <Card.Body className="p-3 position-relative d-flex">
            <div className="flex-grow-1">
              <h5 className="font-weight-bolder text-start mb-0">
                <Image src={tech.image} alt="Image placeholder" fluid className="avatar avatar-sm rounded-circle text-start mb-1 me-3" />
                {tech.name}
              </h5>
              <div className="text-start">
                <span className="font-weight-bolder text-secondary">
                  Experience:   
                  <span className="text-sm text-end text-success font-weight-bolder mt-auto mb-0">
                    {" " + tech.experience}
                  </span>
                </span>
              </div>
            </div>
            <div className="flex-shrink-0">
              <div className="text-end">
                {tech.version && <Badge variant="secondary" pill>{tech.version}</Badge>}
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    ))}
  </Row>
);

const SkillsCard = () => (
  <section>
    <Container className="py-5">
      {Object.values(SKILLS).map(category => (
        <React.Fragment key={category.category}>
          {renderCategory(category)}
        </React.Fragment>
      ))}
    </Container>
  </section>
);

export default SkillsCard;
