import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { SOCIAL } from '../data/data.js'; 

const Social = () => {
  return (
    <section className="py-4">
      <Container>
        <Row>
          <Col lg={6} className="text-start mb-0 pb-0">
            <h3 className="fw-light mb-0 ">Social Media Links</h3>
          </Col>
        </Row>
        <Row className="mt-5">
          {Object.keys(SOCIAL).map((key) => {
              const { name, description, url, image } = SOCIAL[key];
              return (
                <Col sm={6} lg={4} className="mt-lg-0 mt-2 mb-4 text-start" key={key}>
                  <img className="width-32-px mb-3" src={image} alt="logo" />
                  <h5>{name}</h5>
                  <p className="text-sm font-weight-normal pe-3">{description}</p>
                  <a href={url} className="btn btn-outline-secondary btn-sm" target="_blank" rel="noopener noreferrer">Follow</a>
                </Col>
              );
            })}
        </Row>
      </Container>
    </section>
  );
};

export default Social;
