import React from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PROJECTS } from '../data/data.js'; // Assuming the _data.js file is in the same directory as Projects.js

const ProjectCards = () => {
  return (
    <section className="py-5">
      <Container className="special_container">
        {/* <Row className="text-start">
          <Col lg={6}>
            <h3 className="fw-light mb-0 ms-1">My Projects</h3>
          </Col>
        </Row> */}

        <Row className="mt-2 md-projects">
          {Object.values(PROJECTS).map(project => (
            <Col xxl={4} md={6} className="mb-lg-0" key={project.shortName}>
              <Card className="card_div">
                <Card.Img variant="top" src={project.image} />
                <Card.Body className="text-start pb-1">
                    <Card.Title>{project.title}</Card.Title>
                    <Card.Text>{project.description}</Card.Text>
                    <hr className="dark horizontal my-4" />
                    <p className="text-sm mb-1">Technologies used:</p>
                    {project.technologies.map(tech => (
                        <img src={tech.image} alt={tech.tech} key={tech.tech} className="me-3" style={{ height: '1.5em' }} />
                    ))}
                  <div className="card-footer px-0 pb-0 mt-1 mb-0">
                    <a href={project.livePrevieUrl} target="_blank" rel="noopener noreferrer" className="text-uppercase">View Project</a>

                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ProjectCards;
