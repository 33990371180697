import { Container, Row, Col } from 'react-bootstrap';
import './Quote.css';

export default function Quote() {
  return (
    <Container className="special_container">
      <Row>
        <div className="quote-container d-flex">
          <div className="quote-col-left p-2">
            <h3 className="fw-light e-quotation-text">My goal is to build successful web applications within start-ups. I love web developement, design and sharing what I learn. My life goal is to create a free programming school and help people build their own products.</h3>
          </div>
          <Col className="quote-col-right align-self-stretch p-2">
            <a href="https://youtube.com/c/theaworld" target="_blank" rel="noopener noreferrer" className="fs-4 fw-light lh-1 break-text text-center">
              Visit My Youtube Channel
            </a>
          </Col>
        </div>
      </Row>
    </Container>
  );
}
