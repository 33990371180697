import React, { useState } from 'react';
import { Navbar, Nav, Offcanvas } from 'react-bootstrap';
import { FaGithub, FaBitbucket } from 'react-icons/fa'; 
import eliasAvatarImage from '../assets/images/brand/elias-avatar.jpeg'; 
import { useLocation } from 'react-router-dom'; // Import useLocation hook from react-router-dom


const Header = () => {
  const navItems = [
    { text: 'About', link: '/' },
    { text: 'Projects', link: '/projects' },
    { text: 'Skills', link: '/skills' },
    { text: 'Contact', link: '/contact' }
  ];
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleOffcanvasToggle = () => setShowOffcanvas(!showOffcanvas);

  const location = useLocation(); // Get the current location


  return (
    <>
      {/* Navbar Light */}
      <Navbar expand="lg" variant="light" className="shadow-lg bg-white py-2">
        <Navbar.Brand href="/" className="avatar rounded-circle ms-5 me-6" title="Elias Glyptis" data-placement="bottom">
          <img alt="Elias Avatar" src={eliasAvatarImage} />
          <h5 className="my-0 ms-2"><small className="text-dark">Elias Glyptis</small></h5>
        </Navbar.Brand>

        <Navbar.Toggle onClick={handleOffcanvasToggle} className="navbar-toggler shadow-none ms-2" />

        <Navbar.Collapse className="w-100 pt-3 pb-2 py-lg-0 ms-lg-12 ps-lg-5">
          <Nav className="navbar-nav navbar-nav-hover mat-links ms-auto">
            {
              navItems.map((item) => (
                <Nav.Link 
                  key={item.link} href={item.link} 
                  className={`nav-link me-3 ${location.pathname === item.link ? 'active' : ''}`}
                  >
                  {item.text}
                </Nav.Link>
              ))
            }

            <Nav.Link
             href={"https://github.com/eliasglyptis?tab=repositories"} 
             className="nav-link ps-2 d-flex cursor-pointer align-items-center"
             target="_blank" rel="noopener noreferrer"
             >
              <FaGithub style={{ fontSize: "2em" }} />
            </Nav.Link>
           
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {/* End Navbar */}

      {/* Offcanvas */}
      <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Navbar.Brand href="/" className="avatar rounded-circle ms-5 me-6" title="Elias Glyptis" data-placement="bottom" target="_blank">
              <img alt="Elias Avatar" src={eliasAvatarImage} />
              <h5 className="my-0 ms-2"><small className="text-dark">Elias Glyptis</small></h5>
            </Navbar.Brand>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="navbar-nav nav justify-content-end flex-grow-1">
            <Nav.Item>
              
            {
              navItems.map((item) => (
                <Nav.Link key={item.link} href={item.link} className={`nav-link ${location.pathname === item.link ? 'active' : ''}`}>
                  {item.text}
                </Nav.Link>
              ))
            }
            <Nav.Link
             href={"https://github.com/eliasglyptis?tab=repositories"} 
             className="nav-link"
             target="_blank" rel="noopener noreferrer"
             >
              Github
            </Nav.Link>
            </Nav.Item>
            
            
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
      {/* End Offcanvas */}
    </>
  );
};

export default Header;
