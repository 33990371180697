import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  const footerItems = [
    { text: 'About', link: '/' },
    { text: 'Projects', link: '/projects' },
    { text: 'Skills', link: '/skills' },
    { text: 'Contact', link: '/contact' },
    { text: 'Privacy Policy', link: '/privacy-policy' }
  ];
  return (
    <footer className="footer bg-primary pt-2">
      <Container>
        <Row>
          <Col lg={8} className="mb-1 text-start">
            {
              footerItems.map((item) => (
                <a
                key={item.link} href={item.link}
                className="text-white me-xl-5 me-3 mb-sm-0 mb-2 footer-links">
                  {item.text}
                </a>
              ))
            }            
          </Col>
        </Row>
        
        <Row>
          <Col className="text-start mt-1 mb-1">
            <p className="mb-0 text-white">
              © {new Date().getFullYear()} Elias Glyptis
            </p>
          </Col>
        </Row>
      </Container>
      
    </footer>
  );
};

export default Footer;
