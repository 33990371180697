import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Check if user has already made a decision
    const hasConsented = localStorage.getItem('cookieConsent');
    setShowBanner(hasConsented === null); // Show banner if user hasn't made a decision yet
  }, []);

  const handleReject = () => {
    // Set user's consent to false
    localStorage.setItem('cookieConsent', 'false');
    setShowBanner(false); // Hide banner
  };

  const handleAccept = () => {
    // Set user's consent to true
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false); // Hide banner
  };

  return (
    <>
      {showBanner && (
        <div className="cookie-banner shadow-lg ">
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} md={8}>
                <div className="text-center py-3">
                  <p>I utilize cookies through Google Analytics to enhance the functionality of my portfolio website and to optimize your browsing experience. Google Analytics collects data on visitor interactions to help me better understand site usage. By clicking 'Accept,' you consent to the use of cookies for these purposes. Here you can view my <a className="text-white no-effect-hover" href="/privacy-policy" style={{textDecoration: 'underline'}}>Privacy Policy</a></p>
                  <Button variant="danger" className="mx-2 reject" onClick={handleReject}>Decline</Button>
                  <Button variant="success" className="mx-2 accept" onClick={handleAccept}>Accept</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default CookieConsentBanner;
