import React, { useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Stack } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from '@emailjs/browser';

const ContactFormEmail = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  
  const recaptchaRef = useRef();

  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();

    // Set sending message
    setSuccessMessage("Sending...");

    // Send email
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          // Display success message
          setSuccessMessage("Your message was sent successfully! Thanks :)");
        },
        (error) => {
          // Display error message
          console.error('Failed to send email:', error);
          setSuccessMessage("Failed to send email. Please try again later.");
        }
      );

    // Clears the form after sending the email
    e.target.reset();
  };

  return (
    <section>
      <div className="page-header min-vh-100">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xl={9} lg={12} className="d-flex flex-column ms-auto me-auto ms-lg-auto">
              <Card className="d-flex blur shadow-lg mt-4 my-lg-6 mb-5">
                <Card.Header className="p-3 position-relative bg-gradient-dark-sp shadow-dark border-radius-lg">
                  <h3 className="text-white mb-0">Get In Touch</h3>
                </Card.Header>
                <Card.Body className="pb-0">
                  <p className="pb-3 text-start">
                    I am available
                    for any projects that you 'd like to build for your current business, new startup, or any current project you need help with. As a freelancer, I bring a wealth of expertise and dedication to every project, ensuring timely delivery and exceptional results tailored to your specific needs and goals.
                  </p>
                  {/* Success message outside the paragraph tag */}
                  <span className="text-success">{successMessage}</span>
                  <Form onSubmit={sendEmail} id="contact-form" method="post" autoComplete="off">
                    <div className="card-body p-0 my-3 text-start">
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label className="mb-0">Full Name<span className="text-danger"> *</span></Form.Label>
                            <Form.Control type="text" name="user_name" minLength={2} maxLength={30} required />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label className="mb-0">Email<span className="text-danger"> *</span></Form.Label>
                            <Form.Control type="email" name="user_email" required />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label className="mb-0">Phone Number (optional)</Form.Label>
                            <Form.Control type="text" name="phone_number" minLength={5} />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-4">
                            <Form.Label className="mb-0">Organization Name (optional)</Form.Label>
                            <Form.Control type="text" name="organization_name" minLength={2} />
                          </Form.Group>
                        </Col>
                      </Row>
                      
                      <Form.Group className="mb-0 mt-2">
                        <Form.Label className="mb-0">Your message<span className="text-danger"> *</span></Form.Label>
                        <Form.Control as="textarea" name="message" rows={6} minLength={5} required />
                      </Form.Group>

                      <Stack gap={3} className="col-md-6 align-items-center mx-auto">
                          {/* Google reCAPTCHA component */}
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            // size="invisible"
                            sitekey={captchaSiteKey}
                            className="text-start"
                          />
                          <Button type="submit" className="btn bg-primary mb-0">Send Message</Button>
                          <span className="text-success mb-0">{successMessage}</span>
                      </Stack>

                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <style>
        {`
        
        input[type=text], input[type=email] {
          width: 100%;
          padding: 12px 20px;
          margin: 8px 0;
          box-sizing: border-box;
          border: 1px solid #adb6c6;
          border-radius: 4px;
        }

        input[type=text]:focus, input[type=email]:focus {
          border-color: #3f495c; /* Darker color when focused */
          outline: none !important;
        }

        textarea {
          width: 100%;
          height: 150px;
          padding: 12px 20px;
          margin: 8px 0;
          box-sizing: border-box;
          border: 1px solid #adb6c6 !important;
          border-radius: 4px;
          resize: none;
        }

        textarea:focus {
          border-color: #3f495c; /* Darker color when focused */
          outline: none !important;
        }
        input: focus, textarea: focus, select: focus {
          outline: none!important;
        }
        .form-control:focus {
          border-color: inherit;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
        `}
      </style>
    </section>
  );
};

export default ContactFormEmail;
