import React from 'react';
import Layout from '../components/Layout';
import SkillsBackground from '../components/SkillsBackground';
import SkillsCard from '../components/SkillsCard.js'


const About = () => {
  return (
    <Layout>
      <SkillsBackground />
      <SkillsCard />
      
    </Layout>
  );
};

export default About;
