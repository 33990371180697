import React from 'react';
import Layout from '../components/Layout';
// import ProjectsBackground from '../components/ProjectsBackground';
import PrivacyPolicyInfo from '../components/PrivacyPolicyInfo.js';


const PrivacyPolicy = () => {
  return (
    <Layout>
      <PrivacyPolicyInfo />
    </Layout>
  );
};

export default PrivacyPolicy;
